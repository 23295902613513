import { lazy } from 'react';
export default class BBMenubarDescriptor  {

static getDescription() {
    return {
      name: 'BB Menubar',
      component: lazy(() =>  import('./BBMenubar')),
      label: 'Menubar (under development)',
      type:"experimental",
      variables: [
        {
          name: 'query',
          type: 'yasgui',
          label:
            'use parameter ?uri ?label ?parent ?command (not yet implemented) ',
        },

        {
          name: 'menubarconfig',
          type: 'json',
          label: 'JSON configuration for the menubar',
          defaultValue: {
            "label": "Import",
            "icon": "pi pi-home",
            "items": [
                {
                    "label": "riolering",
                    "icon": "pi pi-envelope",
                    "items": [
                        {
                            "subcommand": "popup",
                            "label": "shape",
                            "title": "import shape files",
                            "id": "shape",
                            "icon": "pi pi-box",
                            "fullscreen": false
                        },
                        {
                            "subcommand": "popup",
                            "label": "rib-x",
                            "title": "import rib-x files",
                            "id": "rib-x",
                            "icon": "pi pi-star",
                            "fullscreen": false
                        },
                        {
                            "subcommand": "popup",
                            "label": "Orox",
                            "id": "Orox",
                            "title": "import Orox files",
                            "icon": "pi pi-home",
                            "fullscreen": false
                        }
                      ]
                    }
                  ]
                }
        },

        {
          name: 'useviewwidget',
          type: 'boolean',
          label: 'use a view widget to define multiple widgets (NOT YET IMPLEMENTED)',
        },
        {
          name: 'areas',
          type: 'json',
          // label: 'Column widths or row heights (separated by commas). Value can be a number (which will be set as flex values/verhoudingswaarden) or a px/% value',
          label: 'area definitions',
        },
        
      ],
    }
  }
}
